.quotes {
  border-top: 1px solid #eee;
  background: white;
  background-size: cover;
  margin: 0;
  padding: 20px 0;
  overflow: hidden;

  .carousel {
    position: relative;
    &:before {
     position: absolute;
     left: 0;
     top: 10px;
     display: inline-block;
     font: normal normal normal 14px/1 FontAwesome;
     font-size: inherit;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     transform: translate(0, 0);
     content: "\f10d";
     line-height: 24px;
     font-size: 22px;
     color: #000;
     @include tablet {
       font-size: 36px;
     }
   }
  }

  blockquote {
    margin: 50px auto 40px;
    padding: 0;
    max-width: 745px;
    border: 0;
  }

  p {
    margin: 0;
    line-height: 25px;
    padding-bottom: 10px;
    font-size: 18px;
    color: black;
    font-weight: 300;
    @include tablet {
      line-height: 32px;
      font-size: 22px;
    }
  }

  .cite {
    margin: 0;
    line-height: 25px;
    font-size: 16px;
    font-style: normal;
    color: black;
    font-weight: 400;
  }

  .carousel-controls {
    position: absolute;
    top: 25px;
    width: 25px;
    right: 0;
   .carousel-control {
    color: black;
   }
  }

}

.footer {
  font-weight: 200;
  background-color: darken($brand-primary, 5%);
  color: white;

  ul.footer-nav {
     position: relative;
     display: none;
     width: 100%;
     @include tablet {
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       &:before, &:after {
         display: none;
       }
     }

    li {
       float: none;
       margin: 0;

       a {
       padding: 8px 0;
        border-radius: 0;
        color: white;
        border-top: 3px solid transparent;
        @include tablet {
          padding: 10px;
        }
        @include laptop {
          padding: 7px;
        }
        @include desktop {
          padding: 10px 22px;
        }
        &:hover, &:focus {
          border-top: 3px solid lighten($brand-primary, 20%);
          background-color: inherit;
        }
       }

       &.active {
        a {
          border-top: 3px solid lighten($brand-primary, 20%);
          background-color: inherit;
        }
       }

     }

    }

    .brand {
      @include tablet {
        width: 40%;
        display: block;
        float: left;
      }
      @include laptop {
        width: auto;
        float: none;
      }
      img {
        margin-top: 20px;
        @include laptop {
          margin-top: 40px;
        }
      }

    }

  .footer-text {
    display: inline-block;
    margin: 20px 0;
    font-size: 17px;
    text-align: center;
    @include tablet {
      font-size: 18px;
      margin: 30px 0;
    }
    @include laptop {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .brand-address {
    text-align: center;
  }

  .address-plus {
    text-align: center;
    margin: 20px 0 0;
    @include tablet {
      width: 60%;
      float: left;
    }
    @include laptop {
      width: auto;
      float: none;
    }
    .fa-stack {
      font-size: 1.25em;
      margin-left: 10px;
      @include desktop {
        margin-top: 10px;
      }
      .fa-circle {
        color: #dd4b39;
      }
       &:hover, &:focus {
        .fa-circle {
          color: darken(#dd4b39, 10%);
        }
      }
    }

    .address {
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      width: 170px;
      text-align: left;
      @include mobile {
        width: auto;
      }
      @include laptop {
        text-align: center;
        width: 170px;
      }
      @include desktop {
        width: auto;
        margin-top: 15px;
      }
    }
    .fa-map-marker {
      display: inline-block;
      vertical-align: middle;
      margin: 10px 10px 10px 0;
      @include tablet {
        margin: 3px 10px 0 0;
        font-size: 2.5em;
      }
      @include laptop {
        margin-top: 15px;
        float: left;
      }
    }
  }

  .footer-map {
    @include laptop {
      margin-top: 30px;
    }
    h4 {
      color: #000;
    }
  }

  .footer-content {
    display: inline-block;
    padding-bottom: 25px;
  }

  .copyright {
    padding: 10px 0;
    font-size: 12px;
    background-color: #191919;
    color: white;
    text-align: center;
    a {
      color: white;
    }
    @include tablet {
      .links-copy {
        float: left;
      }
      .client-copy {
        float: right;
      }
    }
  }

}
