.banner {
	background-color: white;
	z-index: 1;

	.upper-navigation {
		background-color: $brand-primary;
	}

	.brand {
		display: block;
		img {
			padding: 20px 0;
			max-width: 300px;
			width: 100%;
		}
	}

	.hamburger {
			margin-top: 4px;
			@include mobile {
				margin-top: 10px;
			}
	}

	// Header Nav
	.nav-pills {
		float: right;
		li {
			a {
				border-radius: 0;
				font-weight: 300;
				&:hover, &:focus {
					background-color: inherit;
				}
			}
		}
		li.active {
			a {
				&:hover, &:focus {

				}
			}
		}

		// Uppers Navigation
		&.upper {
			padding: 5px 0;
			li {
					a {
						padding: 0 8.5px;
						color: #fff;
						font-size:	14px;
						@include desktop {
							font-size: 16px;
						}
						&:hover, &:focus {
							text-decoration: underline;
						}
					}

			}
		}

		// Primary Navigation
		&.lower {
			padding: 20px 0 0;
			li {
				a {
					border-bottom: 3px solid transparent;
					padding: 8px 6px 25px;
					font-size: 16px;
					@include desktop {
						font-size: 18px;
						padding: 10px 10px 30px;
					}
					&:hover, &:focus {
						border-bottom: 3px solid $brand-primary;
					}
				}
				&.active {
					a {
						background-color: inherit;
						border-bottom: 3px solid $brand-primary;
						&:hover, &:focus {
							background-color: inherit;
							color: inherit;
						}
					}
				}
			}
		}


	}
}
