html, body {
	font-family: $font-primary;
	font-size: 18px;
	overflow-x: hidden;
}

img {
	max-width: 100%;
	height: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-secondary;
	font-weight: 800;
}

@include tablet {
	h2 {
		font-size: 40px;
	}
}

h3 {
	font-size: 20px;
	margin-top: 10px;
	@include tablet {
		margin-top: 15px;
		font-size: 24px;
	}
}

ul, ol {
	overflow: hidden;
}

.row-centered {
    text-align:center;
}

.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}

.nav.nav-pills {
	li {
		a {
			color: #000;
		}
	}
}

a {
	&:hover, &:focus {
		text-decoration: none;
	}
}

.gmnoprint {
    display: none;
}

.bg-image {
	text-align: center;
	margin: 0;
}

.hero, .bg-image {
	background-size: cover;
	color: white;
	h1 {
		font-size: 52px;
	}
}

.phone-number {
	color: inherit;
}

section:not(.widget) {
	padding: 40px 0;
	@include tablet {
		padding: 60px 0;
	}
}
