.page-banner {
  background:  darken($brand-primary, 10);
  background-size: cover;
  padding: 30px 0;
  color: #fff;
  h2 {
    margin: 0;
  }
}

.page-content {
  padding: 40px 0;
}

.alignleft, .alignright {
  width: 400px;
  display: block;
  margin: 0 auto 15px;
  border: 1px solid $brand-primary;
}

@include tablet {
  .alignleft {
    margin: 0 15px 5px 0;
  }
  .alignright {
    margin: 0 0 5px 15px;
  }
}
