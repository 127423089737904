.btn-primary, input[type="submit"] {
	border-radius: 2px;
	padding: 10px 15px;
	background-color: $brand-primary;
	border: none;
	color: #fff;
	transition: background-color 0.3s;
    @include desktop {
			padding: 10px 30px;
		}
    &:hover, &:focus {
		background-color: darken($brand-primary, 10%);
	}
}

.btn-primary.btn-block {
	color: white;
	font-family: montserrat, sans-serif;
	text-transform: uppercase;
	font-size: 18px;
	padding: 10px;
	@include tablet {
		font-size: 20px;
	}
	a {
		text-decoration: none;
	}
}

.btn-clear {
	background-color: rgba(0,0,0,0);
	color: white;
	border: 1px solid white;
	border-radius: 0;
	padding: 10px;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 24px;
	 &:hover, &:focus {
		background-color: rgba(255,255,255,0.5);
		color: white;
	}
}

a.scrollup {
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 50%;
    background-color: rgba(28, 152, 208, 0.7);
    border: 1px solid rgb(28, 152, 208);
    padding: 0;
    height: 40px;
    width: 40px;

    &:hover,
    &:focus {
        color: white;
        background-color: darken( rgb(28, 152, 208), 10% );
        text-decoration: none;
    }

    &::before {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 12px;
        width: 12px;
        top: 6px;
        left: 13px;
        position: relative;
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
        vertical-align: middle;
        border-color: #fff;
    }
}
