div.gform_wrapper {
  margin: 0;
  .gfield {
    margin-bottom: 10px;
    .gfield_label {
      margin: 0;
    }
    .ginput_container {
      font-size: 16px;
      input, textarea {
        border: 1px solid #ccc;
      }
    }
    textarea.textarea {
      max-height: 200px;
    }
  }
  .gform_fields {
      .gfield:last-of-type() {
          .gfield_label {
              display: none;
          }
      }
  }
  .gform_footer {
    padding: 0;
  }
}
