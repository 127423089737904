.hero-main {
	background:  linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../images/hero-new-xl-mech.jpg) no-repeat center center;
	background-size: cover;
	padding: 40px 0;
	text-align: center;
	@include laptop {
		padding: 80px 0;
	}
	@include desktop {
		padding: 135px 0;
	}

	img {
		margin-bottom: 30px;
	}

	h1 {
		font-family: $font-secondary;
		text-shadow: 0 1px 2px rgba(0,0,0,0.6);
		// font-weight: bold;
		font-size: 36px;
		@include tablet {
			font-size: 48px;
		}
		@include desktop {
			font-size: 60px;
		}

		&.branding {
		margin-bottom: 35px;
		font-size: 42px;
		@include tablet {
			font-size: 56px;
		}
		@include desktop {
			font-size: 68px;
		}
	}
	}

	p {
  	padding-bottom: 15px;
		font-weight: 800;
  	font-family: $font-secondary;
		text-shadow: 0 1px 2px rgba(0,0,0,0.6);
		font-size: 22px;
		@include tablet {
			font-size: 26px;
		}
		@include desktop {
			font-size: 32px;
			line-height: 40px;
		}
	}

	.btn-primary.btn-block {
		margin-bottom: 15px;
	}

}

#xl-automation {
	padding: 0;

	.bg-image {
		background: url(../images/hero-new-03.jpg) no-repeat center;
		background-attachment: fixed;
		background-size: cover;
		padding: 80px 0;
		@include laptop {
			padding: 125px 0;
		}

		.xl-content {
			display: inline-block;
			padding: 30px;
			background-color: rgba(73, 69, 96, 0.7);
			text-align: center;
			border-radius: 4px;
		}

		p {
			padding-top: 30px;
			padding-bottom: 20px;
			font-weight: 300;
			@include tablet {
				font-size: 22px;
			}
		}

		.overlay {
			background-color: rgba(0,0,0, 0.4);
		    border-radius: 4px;
		    padding: 40px;
		}

		.btn {
			font-size: 16px;
		}

	}
}

.strapline {
	padding: 30px 0;
	background-color: $brand-primary;
	color: white;
	font-weight: 300;
	text-align: center;

	.lead {
		margin-bottom: 0;
		p {
			font-size: 18px;
			@include laptop {
				font-size: 22px;
			}
		}
	}

	.btn-clear {
		padding: 10px;
		font-size: 16px;
		margin-top: 55px;
	}
}

#services {
	.card {
		margin: 30px 0 50px;
		@include tablet {
			margin: 0;
			min-height: 440px;
		}
		@include laptop {
			min-height: 410px;
		}
		@include desktop {
			min-height: 490px;
		}

		img {
			border: 1px solid $brand-primary;
		}

		.card-block {
			.card-title {
				text-transform: uppercase;
				border-bottom: 1px solid $brand-primary;
				padding: 10px 0 20px;
				@include desktop {
					padding: 20px 0;
				}
			}

			.card-text {
				margin: 0;
				padding: 15px 0 20px;
			    font-size: 18px;
			    font-weight: 300;
					@include desktop {
						padding: 30px 0;
					}
			}

			.btn {
				margin-top: 10px;
				width: 100%;
			}

		}
	}
}

// #brands {
// 	text-align: center;
// 	color: white;
// 	background-color: #cccccc;
//
// 	h2 {
// 		font-weight: bold;
// 		// font-size: 40px;
// 	}
//
// 	img {
// 		filter: brightness(0) invert(1);
// 		transition: 500ms all linear;
// 		opacity: 1;
// 		&:hover, &:focus {
// 			filter: none;
// 			opacity: 0.5;
// 		}
// 	}
//
// 	.brand-logos {
// 		padding: 40px 0;
//   	display: inline-block;
// 		img {
// 			margin: 20px 0;
// 		}
// 	}
//
// }

#brands {
	text-align: center;
	color: white;
	background-color: #cccccc;

	h2 {
		// font-weight: bold;
		// font-size: 40px;
	}

	img {
		// filter: brightness(0) invert(1);
		transition: 500ms all linear;
		opacity: 1;
		&:hover, &:focus {
			filter: none;
			opacity: 0.5;
		}
	}

	.slick-slider {
		.slick-arrow {

			&.slick-prev {
				&:before {
					font-family: FontAwesome;
					content: '\f053';
				}
			}
			&.slick-next {
				&:before {
					font-family: FontAwesome;
					content: '\f054';
				}
			}
		}
		.slick-dots {
			bottom: 0;
			li {
				button:before {
					color: #fff;
				}
			}
		}
	}

	.brand-logos {
		padding: 40px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
  	// display: inline-block;
		> div {
			flex: 0 0 50%;
			@include tablet {
				flex: 0 0 calc(1 / 3 * 100%);
			}
			@include laptop {
				flex: 0 0 20%;
			}
		}
		img {
			// width: 165px;
			max-width: 100%;
			margin: 0 auto 10px;
		}
	}

}

#customers {
	text-align: center;

	h2 {
		padding-bottom: 30px;
		font-family: source-sans-pro,sans-serif;
		// font-weight: 300;
		// font-size: 40px;
		margin: 0;
		border-bottom: 1px solid #999;
	}

	.slick-slider {
		.slick-arrow {

			&.slick-prev {
				&:before {
					color: #000;
					font-family: FontAwesome;
					content: '\f053';
				}
			}
			&.slick-next {
				&:before {
					color: #000;
					font-family: FontAwesome;
					content: '\f054';
				}
			}
		}
		.slick-slide {
			outline: none;
		}
		.slick-dots {
			bottom: 0;

		}
	}

	.customer-logos {
		padding: 40px 0;
		.logo {
			@include laptop {
				width: 20%;
			}
		}

		img {
			transition: 500ms all linear;
			margin: 0 auto;
			opacity: 1;
			&:hover, &:focus {
				opacity: 0.5;
			}
		}

	}
}
