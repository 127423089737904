// Media query mixins
@mixin mobile {
	@media (min-width: #{$width_mobile}) {
		@content;
	}
}
@mixin phablet {
	@media (min-width: #{$width_phablet}) {
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$width_tablet}) {
		@content;
	}
}
@mixin laptop {
	@media (min-width: #{$width_laptop}) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: #{$width_desktop}) {
		@content;
	}
}
